<template>
  <aside
    id="app-sidebar"
    :class="{ open: isSidebarOpen, closed: !isSidebarOpen, 'hidden': !isSidebarOpen }"
    class="w-full md:w-1/2 lg:w-1/4 h-screen flex fixed flex-col right-0 bg-gray-200 dark:bg-gray-900"
    :style="{'z-index': 40}"
  >
<!--    <div-->
<!--      class="block py-3 px-6 bg-gray-800 text-gray-100 text-lg font-semibold"-->
<!--    >-->
<!--      {{ $t('MENU') }}-->
<!--    </div>-->
    <ul class="text-left text-gray-600 dark:text-gray-200 tracking-wider">
      <li>
        <nuxt-link class="block border-b border-gray-400 py-4 px-5" to="/">
          <GMIcon
            name="calendar"
            class="mr-1 -mt-1 w-6 h-6 inline-block text-gray-600 dark:text-gray-500"
          />
          {{ $t('CONTRACTS') }}
        </nuxt-link>
      </li>
<!-- Temporary removal of movies link https://dev.azure.com/GlommenMjosen/Portaler%20og%20SBL-app/_workitems/edit/2646 -->
<!--      <li>-->
<!--        <a-->
<!--          class="block border-b border-gray-800 py-4 px-5"-->
<!--          rel="noreferrer"-->
<!--          target="_blank"-->
<!--          href="https://www.glommen-mjosen.no/Arkiv/filmer-for-sbl-app"-->
<!--        >-->
<!--          <svg-->
<!--            class="inline-block mr-1 fill-current align-top text-gray-500"-->
<!--            xmlns="http://www.w3.org/2000/svg"-->
<!--            width="24"-->
<!--            height="24"-->
<!--            viewBox="0 0 512 512"-->
<!--          >-->
<!--            <path-->
<!--              fill="currentColor"-->
<!--              d="M488 64h-8v20c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12V64H96v20c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12V64h-8C10.7 64 0 74.7 0 88v336c0 13.3 10.7 24 24 24h8v-20c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v20h320v-20c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v20h8c13.3 0 24-10.7 24-24V88c0-13.3-10.7-24-24-24zM96 372c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12H44c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm272 208c0 6.6-5.4 12-12 12H156c-6.6 0-12-5.4-12-12v-96c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v96zm0-168c0 6.6-5.4 12-12 12H156c-6.6 0-12-5.4-12-12v-96c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v96zm112 152c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40zm0-96c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40z"-->
<!--            />-->
<!--          </svg>-->
<!--          {{ $t('MOVIES') }}-->
<!--        </a>-->
<!--      </li>-->
      <li>
        <nuxt-link
          class="flex flex-1 border-b border-gray-400 py-4 px-5"
          to="/queue"
        >
          <svg
            class="inline-block mr-1 fill-current align-top text-gray-600 dark:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M20 19.59V8l-6-6H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c.45 0 .85-.15 1.19-.4l-4.43-4.43c-.8.52-1.74.83-2.76.83-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1.02-.31 1.96-.83 2.75L20 19.59zM9 13c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3z"
            />
          </svg>
          <div class="flex flex-1 justify-between">
            {{ $t('QUEUE') }}
            <GMOrb
              class="ml-2 tracking-normal text-white"
              :class="{
                'bg-red-800': queueSize > 0,
                'bg-blue-800': queueSize <= 0
              }"
            >
              {{ queueSize }}
            </GMOrb>
          </div>
        </nuxt-link>
      </li>
      <li>
        <nuxt-link
            class="flex flex-1 border-b border-gray-400 py-4 px-5"
            to="/track-damage"
        >
          <IconTruck class="w-5 h-5 mr-1 text-gray-600 dark:text-gray-500" />
          <div class="flex flex-1 justify-between items-center">
            {{ $t('TRACK_DAMAGE_TITLE') }}
            <GMOrb class="ml-2 tracking-normal text-white bg-blue-800">
              <GMLoader v-if="isLoadingOperationsWithTrackDamage"/>
              <template v-else-if="trackDamageFetchError">
                <GMIcon name="exclamation" class="w-5 h-5 mr-1" :title="trackDamageFetchError" />
              </template>
              <span v-else>{{ unconfirmedOperationsWithTrackDamage ? unconfirmedOperationsWithTrackDamage.length : 0 }}</span>
            </GMOrb>
          </div>
        </nuxt-link>
      </li>
      <li>
        <nuxt-link
            class="flex flex-1 border-b border-gray-400 py-4 px-5"
            to="/parked-transport"
        >
          <IconTruck class="w-5 h-5 mr-1 text-gray-600 dark:text-gray-500" />
          <div class="flex flex-1 justify-between items-center">
            {{ $t('TRANSPORT_PARKED_TITLE') }}
            <GMOrb class="ml-2 tracking-normal text-white bg-blue-800">
              <GMLoader v-if="isLoadingParkedOrders"/>
              <template v-else-if="parkedTransportFetchError">
                <GMIcon name="exclamation" class="w-5 h-5 mr-1" :title="$te(`PROJECT_SERVER_ERROR.${parkedTransportFetchError}`) ? $t(`PROJECT_SERVER_ERROR.${parkedTransportFetchError}`) : parkedTransportFetchError" />
              </template>
              <span v-else>{{ parkedTransportOrders ? parkedTransportOrders.length : 0 }}</span>
            </GMOrb>
          </div>
        </nuxt-link>
      </li>
<!--      <li>-->
<!--        <nuxt-link-->
<!--          class="flex flex-1 border-b border-gray-800 py-4 px-5"-->
<!--          to="/projects-ready-2-close"-->
<!--        >-->
<!--          <svg-->
<!--            xmlns="http://www.w3.org/2000/svg"-->
<!--            xmlns:xlink="http://www.w3.org/1999/xlink"-->
<!--            aria-hidden="true"-->
<!--            role="img"-->
<!--            class="inline-block mr-1 fill-current align-top text-gray-500"-->
<!--            width="24"-->
<!--            height="24"-->
<!--            preserveAspectRatio="xMidYMid meet"-->
<!--            viewBox="0 0 24 24"-->
<!--          >-->
<!--            <g fill="currentColor">-->
<!--              <path d="M9 2a1 1 0 0 0 0 2h2a1 1 0 1 0 0-2H9Z"></path>-->
<!--              <path-->
<!--                fill-rule="evenodd"-->
<!--                d="M4 5a2 2 0 0 1 2-2a3 3 0 0 0 3 3h2a3 3 0 0 0 3-3a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5Zm9.707 5.707a1 1 0 0 0-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4Z"-->
<!--                clip-rule="evenodd"-->
<!--              ></path>-->
<!--            </g>-->
<!--          </svg>-->
<!--          <div class="flex flex-1 justify-between items-center">-->
<!--            {{ $t('PROJECTS_READY_FOR_COMPLETION') }}-->
<!--            <GMOrb-->
<!--              class="ml-2 tracking-normal"-->
<!--              :class="{-->
<!--                'bg-red-800': !!projectsReady2CloseFetchError,-->
<!--                'bg-blue-800': !projectsReady2CloseFetchError-->
<!--              }"-->
<!--            >-->
<!--              <GMLoader v-if="isLoadingProjectsReady2Close"/>-->
<!--              <template v-else-if="projectsReady2CloseFetchError">-->
<!--                <GMIcon name="exclamation" class="w-5 h-5 mr-1" :title="$te(`PROJECT_SERVER_ERROR.${projectsReady2CloseFetchError}`) ? $t(`PROJECT_SERVER_ERROR.${projectsReady2CloseFetchError}`) : projectsReady2CloseFetchError" />-->
<!--              </template>-->
<!--              <span v-else>{{ projectsReady2Close ? projectsReady2Close.length : 0 }}</span>-->
<!--            </GMOrb>-->
<!--          </div>-->
<!--        </nuxt-link>-->
<!--      </li>-->
      <li>
        <nuxt-link
          class="flex flex-1 border-b border-gray-400 py-4 px-5"
          to="/non-invoiced-orders"
        >
          <IconDocumentText class="w-5 h-5 mr-1 text-gray-600 dark:text-gray-500" />
          <div class="flex flex-1 justify-between items-center">
            {{ $t('UNHANDLED_INVOICES') }}
            <GMOrb
              class="ml-2 tracking-normal text-white"
              :class="{
                'bg-red-800': !!nonInvoicedOrdersFetchError,
                'bg-blue-800': !nonInvoicedOrdersFetchError
              }"
            >
              <GMLoader v-if="isLoadingNonInvoicedOrders"/>
              <template v-else-if="nonInvoicedOrdersFetchError">
                <GMIcon name="exclamation" class="w-5 h-5 mr-1" :title="$te(`PROJECT_SERVER_ERROR.${nonInvoicedOrdersFetchError}`) ? $t(`PROJECT_SERVER_ERROR.${nonInvoicedOrdersFetchError}`) : nonInvoicedOrdersFetchError" />
              </template>
              <span v-else>{{ nonInvoicedOrders ? nonInvoicedOrders.length : 0 }}</span>
            </GMOrb>
          </div>
        </nuxt-link>
      </li>
    </ul>

    <ul class="absolute bottom-0 w-full text-gray-600 dark:text-gray-300">
      <li class="border-t border-b border-gray-400 px-5">
          <div class="flex w-full text-sm py-2.5 text-left justify-between items-center">
            <div class="flex w-full space-x-1">
              <span :class="{'font-bold' : !darkModeModel}">{{ $t('LIGHT_THEME') }}</span>
              <span>/</span>
              <span :class="{'font-bold' : darkModeModel}">{{ $t('DARK_THEME') }}</span>
            </div>


            <GMToggle
              v-model="darkModeModel"
              class="ml-2 tracking-normal bg-gray-400">
            </GMToggle>
          </div>
      </li>

      <li class="border-b border-gray-400 px-5">
        <button
          class="flex w-full text-sm py-2.5 text-left hover:underline"
          type="button"
          @click="reload()"
        >
          <GMIcon name="download" class="mr-1 w-5 h-5 text-gray-600 dark:text-gray-500" />
          {{ $t('LOOK_FOR_NEW_VERSION') }}
        </button>
      </li>
      <li class="border-b border-gray-400 px-5">
        <button
          class="flex w-full text-sm py-2.5 text-left text-yellow-500 dark:text-yellow-300 hover:underline"
          type="button"
          @click="updateData()"
        >
          <GMIcon name="refresh" class="mr-1 w-5 h-5 text-yellow-500" />
          {{ $t('CLEAN_DATA_HEADER') }}
        </button>
      </li>
      <li class="border-b border-gray-400 px-5">
        <button
          class="flex w-full text-sm text-gray-600 dark:text-gray-500 py-2.5 text-left hover:underline"
          type="button"
          @click="signOut()"
        >
          <GMIcon name="lock" class="mr-1 w-5 h-5 text-gray-600  dark:text-gray-500" />
          {{ $t('LOGG_OUT') }}
        </button>
      </li>
      <li>
        <div
          class="flex flex-wrap justify-between py-3 mx-2 px-3 text-xxs text-left tracking-widest"
        >
          <span class="break-all text-gray-600 dark:text-gray-500">{{ $t('VERSION') }} {{ currentVersion }}:{{ env }}</span>
          <GMButton
            v-if="currentUser && currentUser.Name"
            icon-before="user"
            size="small"
            class="-my-3 -mr-3 !text-blue-500 dark:!text-blue-300"
            @click="showUserData = true"
          >
            {{ currentUser.Name }}
          </GMButton>
        </div>
        <div></div>
        <div></div>
      </li>
    </ul>
    <GMModal
      ref="showUserDataModal"
      :show="showUserData"
      @close="showUserData = false"
    >
      <template #header>
        {{ $t('USER_INFORMATION') }}
      </template>
      <template #default>
        <table class="w-full border-b border-gray-300 dark:border-gray-700">
          <caption class="sr-only">
            {{
              $t('USER_INFORMATION')
            }}
          </caption>
          <tr class="border-t border-gray-300 dark:border-gray-700">
            <th class="py-2 text-left" scope="row">
              {{ $t('NAME') }}
            </th>
            <td class="py-2">
              {{ currentUser.Name }}
            </td>
          </tr>
          <tr class="border-t border-gray-300 dark:border-gray-700">
            <th class="py-2 text-left" scope="row">
              {{ $t('EMAIL') }}
            </th>
            <td class="py-2">
              {{ currentUser.Email }}
            </td>
          </tr>
          <tr class="border-t border-gray-300 dark:border-gray-700">
            <th class="py-2 text-left" scope="row">
              {{ $t('PHONE') }}
            </th>
            <td class="py-2">
              {{ currentUser.Mobile }}
            </td>
          </tr>
          <tr class="border-t border-gray-300 dark:border-gray-700">
            <th class="py-2 text-left" scope="row">
              {{ $t('COMPANY') }}
            </th>
            <td class="py-2">
              {{ isAsUser ? $t('GLOMMEN_SKOG') : $t('GLOMMEN_MJOSEN') }}
            </td>
          </tr>
        </table>
      </template>
      <template #footer>
        <GMButton class="w-full" @click="$refs.showUserDataModal.close()">
          {{ $t('CLOSE') }}
        </GMButton>
      </template>
    </GMModal>
  </aside>
</template>

<script lang="ts">
import {useDraftsDb} from '@/use/indexedDb/draftsDb'


import {
  confirm,
  ConfirmationTypes,
  formatNumber,
  GMButton,
  GMIcon,
  GMLoader,
  GMModal,
  GMOrb,
  GMToggle
} from '@gm/components'
import {useDataStore} from '@/use/data/store'
import {useQueueSizeStore} from '@/use/ui/queueSize'
import {useSidebar} from '@/use/ui/sidebar'
import {useUserStore} from '@/use/user/store'
import {useProjectStore} from '~/use/project/store'
import {useProjectOrdersStore} from '~/use/projectOrders/store'
import {useTransportStore} from '~/use/transport/store';
import {useTrackDamageStore} from "~/use/trackDamage/store";
import {useDarkModeStore} from "~/use/ui/darkMode";

export default defineComponent({
  name: 'AppSidebar',
  components: {
    GMToggle,
    GMButton,
    GMIcon,
    GMOrb,
    GMModal,
    GMLoader
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { $msal, $config } = useNuxtApp()
    const { clearData } = useDataStore()
    const { currentUser, isAsUser } = useUserStore()
    const { draftsDb } = useDraftsDb()
    const { isSidebarOpen, closeSidebar } = useSidebar()
    const { queueSize } = useQueueSizeStore()
    const { t } = useI18n()
    const runtimeConfig = useRuntimeConfig()
    const env = computed(() => runtimeConfig.public.environment)
    const currentVersion = computed(() => runtimeConfig.public.version)
    const {
      isLoadingProjectsReady2Close,
      projectsReady2CloseFetchError,
      projectsReady2Close
    } = useProjectStore()
    const {
      isLoadingNonInvoicedOrders,
      nonInvoicedOrders,
      nonInvoicedOrdersFetchError,
    } = useProjectOrdersStore()
    const {
      isLoadingParkedOrders,
      parkedTransportOrders,
      parkedTransportFetchError,
    } = useTransportStore()

    const {
      isLoadingOperationsWithTrackDamage,
      unconfirmedOperationsWithTrackDamage,
      trackDamageFetchError
    } = useTrackDamageStore()

    const {
      isDarkMode,
      setDarkMode
    } = useDarkModeStore()


    const darkModeModel = computed({
      get: () => isDarkMode.value,
      set: (value) => {
        //@ts-ignore
        setDarkMode(value)
      }
    })

    const showUserData = ref(false)

    const updateSideMenuHeight = () => {
      const topBanner = document.getElementById('app-header');
      const sideMenu = document.getElementById('app-sidebar');

      if (topBanner && sideMenu) {
        const topBannerHeight = topBanner.offsetHeight;

        // Adjust side menu's position and height
        sideMenu.style.top = `${topBannerHeight}px`;
        sideMenu.style.height = `calc(100% - ${topBannerHeight}px)`;
      }
    }

    onMounted(() => {
      updateSideMenuHeight();
      window.addEventListener('resize', updateSideMenuHeight);
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateSideMenuHeight);
    })

    const signOut = async (): Promise<void> => {
      await clearData()
      $msal.logoutRedirect({
        postLogoutRedirectUri: `${window.location.origin}/login`
      })
      // $auth.logout()
      // $auth.reset()
    }

    const reload = (): void => {
      window.location.reload()
    }

    const updateData = async (): Promise<void> => {
      const drafts = await draftsDb.getAll()

      // If no drafts and queue, or user confirms deletion
      // clear data and route to loading
      if (
        (drafts.length === 0 && queueSize.value === 0) ||
        (await confirm(
          t('CLEAN_DATA_TEXT', {
            drafts: drafts.length,
            queue: queueSize.value
          }) as string,
          {
            type: ConfirmationTypes.warning,
            icon: 'exclamation',
            options: [
              {
                text: t('YES_CLEAN_DATA') as string,
                value: true,
                primary: true,
                iconAfter: 'trash',
                destructive: true
              },
              {
                text: t('CANCEL') as string,
                value: false
              }
            ]
          }
        ))
      ) {
        await clearData()
        router.push('/loading')
      }
    }

    watch(() => route.path, () => {
      if (isSidebarOpen.value) {
        closeSidebar()
      }
    })

    return {
      currentUser,
      env,
      formatNumber,
      isAsUser,
      isSidebarOpen,
      queueSize,
      reload,
      showUserData,
      signOut,
      updateData,
      currentVersion,
      isLoadingProjectsReady2Close,
      projectsReady2CloseFetchError,
      projectsReady2Close,
      isLoadingNonInvoicedOrders,
      nonInvoicedOrders,
      nonInvoicedOrdersFetchError,
      isLoadingParkedOrders,
      parkedTransportOrders,
      parkedTransportFetchError,
      isLoadingOperationsWithTrackDamage,
      unconfirmedOperationsWithTrackDamage,
      trackDamageFetchError,
      darkModeModel
    }
  }
})
</script>
